export enum Tech {
  D3 = "d3",
  MONGO = "mongo",
  NEO4J = "neo4j",
  NEXT = "next",
  NODE = "node",
  POSTGRES = "postgres",
  PYTHON = "python",
  REACT = "react",
  VUE = "vue",
}
