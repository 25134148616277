import React, { useEffect, useState } from "react"
import { App } from "../models"
import { AppCard } from "./"

export type AppCarouselProps = {
  apps: App[]
}

export const AppCarousel = (props: AppCarouselProps) => {
  const { apps } = props
  const [carouselIndex, setCarouselIndex] = useState<number>(0)
  const [intervalID, setIntervalID] = useState<number | undefined>()

  useEffect(() => {
    const maxIndex = apps.length
    const id = setInterval(() => {
      setCarouselIndex(index => (index + 1) % maxIndex)
    }, 5000)
    setIntervalID(Number(id))
    return () => {
      clearInterval(id)
    }
  }, [])

  const selectCarouselIndex = (n: number) => {
    setCarouselIndex(apps.length - n - 1)
    clearInterval(intervalID)
  }

  return (
    <div>
      <div className="appCarousel">
        {apps.map((app, i) => (
          <AppCard
            app={app}
            style={{
              transform: `translateX(${-100 * (i - carouselIndex)}vw)`,
            }}
            key={app.name}
            CardSurfaceProps={{
              "aria-hidden": i !== carouselIndex,
            }}
            aria-current={i === carouselIndex ? true : undefined}
          ></AppCard>
        ))}
      </div>
      <div className="appCarousel-control">
        {apps.map((app, i) => (
          <button
            className={
              apps.length - i - 1 === carouselIndex
                ? "appCarousel-activeButton"
                : undefined
            }
            aria-label={`Jump to carousel item ${apps.length - i - 1}`}
            onClick={() => selectCarouselIndex(i)}
            key={app.name}
          ></button>
        ))}
      </div>
    </div>
  )
}

export default AppCarousel
