import { App, Tech } from "../models"
import lingraphic from "../../static/lingraphic.png"
import babynames from "../../static/babynames.png"
import colourconverter from "../../static/colourconverter.png"
import climateChange from "../../static/climateChange.png"
import triviaApi from "../../static/triviaApi.png"
const backgroundURL = babynames
import d3 from "../../static/d3.svg"
import vue from "../../static/vue.svg"
import mongo from "../../static/mongo.svg"
import neo4j from "../../static/neo4j.svg"
import next from "../../static/next.svg"
import node from "../../static/node.svg"
import postgres from "../../static/postgres.svg"
import python from "../../static/python.svg"
import react from "../../static/react.svg"

const getTechBadge = (tech: Tech) => {
  switch (tech) {
    case Tech.D3:
      return d3
    case Tech.VUE:
      return vue
    case Tech.MONGO:
      return mongo
    case Tech.NODE:
      return node
    case Tech.NEO4J:
      return neo4j
    case Tech.NEXT:
      return next
    case Tech.POSTGRES:
      return postgres
    case Tech.PYTHON:
      return python
    case Tech.REACT:
    default:
      return react
  }
}

export const apps: App[] = [
  {
    name: "Babynamr",
    description:
      "Compare the popularity of baby names in the UK over the last 20 years.",
    url: "https://uk-baby-names.netlify.app/",
    techs: [Tech.VUE, Tech.MONGO, Tech.NODE, Tech.D3].map(t => ({
      name: t,
      icon: getTechBadge(t),
    })),
    backgroundURL: babynames,
  },
  {
    name: "Trivia API",
    description:
      "API used to submit and request multiple choice trivia questions.",
    url: "https://trivia.willfry.co.uk",
    techs: [Tech.NEXT, Tech.POSTGRES, Tech.REACT, Tech.PYTHON].map(t => ({
      name: t,
      icon: getTechBadge(t),
    })),
    backgroundURL: triviaApi,
  },
  {
    name: "React Chart Library",
    description:
      "A library of reusable React components to easily build data visualisations",
    url: "https://www.npmjs.com/package/react-chart-library",
    techs: [Tech.REACT, Tech.D3].map(t => ({
      name: t,
      icon: getTechBadge(t),
    })),
    backgroundURL: babynames,
  },
  {
    name: "Colour Tool",
    description: "HSL-RGB-HEX converter",
    url: "https://colour-tool.netlify.app/",
    techs: [Tech.VUE, Tech.D3].map(t => ({
      name: t,
      icon: getTechBadge(t),
    })),
    backgroundURL: colourconverter,
  },
  {
    name: "Climate Change Tracker",
    description: "Track the change in climate in the UK over time",
    url: "https://climate-data.willfry.co.uk",
    techs: [Tech.REACT, Tech.NEXT, Tech.D3].map(t => ({
      name: t,
      icon: getTechBadge(t),
    })),
    backgroundURL: climateChange,
  },
  // {
  //   name: "CarHunter",
  //   description: "Track the depreciation of used cars",
  //   url: "https://willfry.co.uk/used-car-prices",
  //   techs: [Tech.VUE, Tech.MONGO, Tech.NODE].map(t => ({
  //     name: t,
  //     icon: getTechBadge(t),
  //   })),
  //   backgroundURL,
  // },
  {
    name: "Lingraphic",
    description:
      "Search the etymology of over 1,000,000 words in over 2,000 languages.",
    url: "https://lingraphic.app",
    techs: [Tech.VUE, Tech.PYTHON, Tech.NEO4J, Tech.D3].map(t => ({
      name: t,
      icon: getTechBadge(t),
    })),
    backgroundURL: lingraphic,
  },
]
