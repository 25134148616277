import React, { useEffect, useState, useRef, CSSProperties } from "react"
import { AppCarousel, Logo } from "../components"
import { apps } from "../resources"
import "../styles/styles.scss"
import linkedin from "../../static/linkedin.svg"
import twitter from "../../static/twitter.svg"
import email from "../../static/email.svg"
import instagram from "../../static/instagram.svg"
import gitlab from "../../static/gitlab.svg"
import headshot from "../../static/headshot2.jpg"
import DarkModeToggle from "react-dark-mode-toggle"

const InlineList = (props: { techs: string[] }) => (
  <ul className="inline-list">
    {props.techs.map(tech => (
      <li key={tech}>{tech}</li>
    ))}
  </ul>
)

const getStyle = (
  closeness: number,
  darkMode: boolean
): Partial<CSSProperties> => {
  return {
    backgroundColor: darkMode
      ? `hsla(304, 5%, 25%, ${closeness})`
      : `hsla(304, 65%, 98%, ${closeness})`,
    boxShadow:
      closeness > 1.1
        ? "unset"
        : closeness > 0.9
        ? `0 8px 6px -6px #${darkMode ? "00000020" : "00000044"}`
        : "unset",
    transition:
      "box-shadow 0.5s ease, background-color 0.25s ease-in-out, color 0.25s ease-in-out",
  }
}

export default function Home() {
  const [darkMode, setDarkMode] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setDarkMode(
        window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
      )
    }
  }, [])

  const aboutMeRef = useRef(null)
  const [aboutMeTarget, setAboutMeTarget] = useState(0)
  const myProjects = useRef(null)
  const [myProjectsTarget, setMyProjectsTarget] = useState(0)
  const skillsRef = useRef(null)
  const [skillsTarget, setSkillsTarget] = useState(0)
  const mainRef = useRef(null)

  const onScroll = () => {
    // transition the headers towards a solid background as they approach
    // the target sticky position. could probably abstract this logic into
    // a function but it's only a few lines and won't be reused out of here.
    const aboutMeTop = aboutMeRef?.current.getBoundingClientRect()?.y
    const myProjectsTop = myProjects?.current.getBoundingClientRect()?.y
    const skillsTop = skillsRef?.current.getBoundingClientRect()?.y
    const pageHeight = mainRef.current
      ? mainRef.current.getBoundingClientRect().height
      : null
    const targetTop = pageHeight * 0.15

    // starting from 100 pixels away, increase target by 1% per pixel
    const aboutMeTarget = (100 - (aboutMeTop - targetTop)) / 100
    const myProjectsTarget = (100 - (myProjectsTop - targetTop)) / 100
    const skillsTarget = (100 - (skillsTop - targetTop)) / 100
    setAboutMeTarget(aboutMeTarget)
    setMyProjectsTarget(myProjectsTarget)
    setSkillsTarget(skillsTarget)
  }

  return (
    <div
      id="page"
      ref={mainRef}
      onScroll={onScroll}
      className={darkMode ? "darkMode" : "lightMode"}
    >
      <DarkModeToggle
        className={"darkModeToggle"}
        onChange={() => {
          setDarkMode(!darkMode)
        }}
        checked={darkMode}
        speed={2}
        size={50}
      />
      <div id="header">
        <div className="header-background" />
        <div className="header-background" />
        <div id="header-title">
          <h1>
            Will Fry<span className="dot">.</span>
          </h1>
          <h1>
            Software Engineer<span className="dot">.</span>
          </h1>
          <h1>
            London<span className="dot">.</span>
          </h1>
        </div>
      </div>
      <header>
        <div className="header-background" />
        <div className="header-background" />
        <div className="header-background" />
        <div id="logo-container">
          <Logo />
        </div>
      </header>
      <main>
        <section id="about-me">
          <h1 ref={aboutMeRef} style={getStyle(aboutMeTarget, darkMode)}>
            Hi.
          </h1>
          <img id="headshot" src={headshot} alt="Photo of me" />
          <p>
            I'm Will, a London-based software engineer with a passion for
            creating web apps that tap into the world of open data.
          </p>
        </section>
        <section id="projects">
          <h1 ref={myProjects} style={getStyle(myProjectsTarget, darkMode)}>
            My Projects
          </h1>
          <p>
            Some of the personal projects I've worked on recently. The code for
            all of my projects is hosted on{" "}
            <a href="https://gitlab.com/BillyBobFry">Gitlab</a>.
          </p>
          <AppCarousel apps={apps} />
        </section>
        <section id="skills">
          <h1 ref={skillsRef} style={getStyle(skillsTarget, darkMode)}>
            Skills
          </h1>
          <ul>
            <li>
              <b>Frontend:</b>{" "}
              <InlineList
                techs={["Javascript", "Typescript", "React", "Vue", "HTML/CSS"]}
              />
            </li>
            <li>
              <b>Backend:</b> <InlineList techs={["Node.js", "Python"]} />
            </li>
            <li>
              <b>Data:</b>{" "}
              <InlineList
                techs={[
                  "Elasticsearch",
                  "MongoDB",
                  "MSSQL",
                  "Neo4j",
                  "Oracle",
                  "Postgres",
                  "RDF",
                ]}
              />
            </li>
            <li>
              <b>Networking:</b>{" "}
              <InlineList
                techs={[
                  "TCP/IP Model",
                  "LANs/VLANs",
                  "Routing",
                  "Firewalls",
                  "NAT",
                ]}
              />
            </li>
          </ul>
        </section>
        <section id="contact">
          <h1>Contact</h1>
          <div id="contact-addresses">
            <a href="https://twitter.com/willfry" target="_blank">
              <img id="twitter" src={twitter} alt="twitter-logo" />
            </a>
            <a href="mailto: will.r.fry@gmail.com" target="_blank">
              <img id="email" src={email} alt="gmail-logo" />
            </a>
            <a
              href="https://www.instagram.com/williamilliam/?hl=en"
              target="_blank"
            >
              <img id="instagram" src={instagram} alt="instagram-logo" />
            </a>
            <a
              href="https://www.linkedin.com/in/will-fry-ba7118a1/"
              target="_blank"
            >
              <img id="linkedin" src={linkedin} alt="linkedin-logo" />
            </a>
          </div>
        </section>
        <section id="git">
          <a href="https://gitlab.com/BillyBobFry" target="_blank">
            <img id="linkedin" src={gitlab} alt="gitlab-logo" />
          </a>
        </section>
      </main>
    </div>
  )
}
