import React from "react"

export interface LogoProps {
  fills?: [string, string, string]
}

export const Logo = (props: LogoProps) => (
  <svg
    width="80"
    height="79"
    viewBox="0 0 80 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.5977 67H47.6914L37.3203 32.582C36.8281 31.0586 36.2773 29.1367 35.668 26.8164C35.0586 24.4961 34.7422 23.1016 34.7188 22.6328C34.2031 25.7266 33.3828 29.1133 32.2578 32.793L22.2031 67H16.2969L2.62109 15.6016H8.94922L17.0703 47.3477C18.1953 51.8008 19.0156 55.832 19.5312 59.4414C20.1641 55.1523 21.1016 50.957 22.3438 46.8555L31.5547 15.6016H37.8828L47.5508 47.1367C48.6758 50.7695 49.625 54.8711 50.3984 59.4414C50.8438 56.1133 51.6875 52.0586 52.9297 47.2773L61.0156 15.6016H67.3438L53.5977 67Z"
      fill={props.fills ? props.fills[0] : "#ffffffcc"}
    />
    <path
      d="M58.5977 67H52.6914L42.3203 32.582C41.8281 31.0586 41.2773 29.1367 40.668 26.8164C40.0586 24.4961 39.7422 23.1016 39.7188 22.6328C39.2031 25.7266 38.3828 29.1133 37.2578 32.793L27.2031 67H21.2969L7.62109 15.6016H13.9492L22.0703 47.3477C23.1953 51.8008 24.0156 55.832 24.5312 59.4414C25.1641 55.1523 26.1016 50.957 27.3438 46.8555L36.5547 15.6016H42.8828L52.5508 47.1367C53.6758 50.7695 54.625 54.8711 55.3984 59.4414C55.8438 56.1133 56.6875 52.0586 57.9297 47.2773L66.0156 15.6016H72.3438L58.5977 67Z"
      fill={props.fills ? props.fills[1] : "#ffffffaa"}
    />
    <path
      d="M63.5977 67H57.6914L47.3203 32.582C46.8281 31.0586 46.2773 29.1367 45.668 26.8164C45.0586 24.4961 44.7422 23.1016 44.7188 22.6328C44.2031 25.7266 43.3828 29.1133 42.2578 32.793L32.2031 67H26.2969L12.6211 15.6016H18.9492L27.0703 47.3477C28.1953 51.8008 29.0156 55.832 29.5312 59.4414C30.1641 55.1523 31.1016 50.957 32.3438 46.8555L41.5547 15.6016H47.8828L57.5508 47.1367C58.6758 50.7695 59.625 54.8711 60.3984 59.4414C60.8438 56.1133 61.6875 52.0586 62.9297 47.2773L71.0156 15.6016H77.3438L63.5977 67Z"
      fill={props.fills ? props.fills[2] : "#ffffff99"}
    />
  </svg>
)
