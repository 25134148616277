import React, { HTMLAttributes } from "react"
import { App } from "../models"
import { TechBadge } from "./"

export interface AppCardProps extends HTMLAttributes<HTMLAnchorElement> {
  app: App
  CardSurfaceProps?: HTMLAttributes<HTMLDivElement>
}

export const AppCard = (props: AppCardProps) => {
  const {
    app: { backgroundURL, description, name, techs, url },
    CardSurfaceProps,
    ...rootProps
  } = props
  return (
    <a className="appCard" {...rootProps} href={url}>
      <div className="appCard-bg">
        <img src={backgroundURL} />
      </div>
      <div className="appCard-surface" {...CardSurfaceProps}>
        <h2>{name}</h2>
        <p>{description}</p>
        {techs && (
          <div className="appCard-techs">
            {techs.map(tech => (
              <TechBadge tech={tech} key={tech.name} />
            ))}
          </div>
        )}
      </div>
    </a>
  )
}

export default AppCard
