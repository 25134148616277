import React, { CSSProperties, useEffect, useState } from "react"
import { Tech } from "../models"

export interface TechBadgeProps {
  tech: { name: Tech; icon: any }
}

export const TechBadge = (props: TechBadgeProps) => (
  <div className="tech-badge">
    <div>
      <img src={props.tech.icon} alt={`${props.tech.name} logo`} />
    </div>
  </div>
)
